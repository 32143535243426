.page-error main {
  align-items: center;
  justify-content: center;
  display: flex;
}

.page-error main > .layout.-horizontal {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 800px;
}

.page-error .error-illustration {
  flex: 1 0 70%;
  margin-right: var(--spacing-10);
}

.page-error .error-illustration img {
  width: 100%;
  height: auto;
}

.page-error .error-message h1 {
  font-size: 120px;
  text-transform: none;
  color: var(--color-scalero);
}

.page-error .error-message h2 {
  font-size: 30px;
  letter-spacing: 0.27px;
  line-height: 36px;
  font-weight: normal;
  text-transform: none;
  color: var(--color-scalero);
}

.page-error .error-message pre {
  border-radius: var(--spacing-01);
  padding: var(--spacing-01);
  border: 1px solid var(--color-neutral-20);
  background-color: var(--color-neutral-10);
}
