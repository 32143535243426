.alert-box {
  border: 1px solid transparent;
  border-radius: var(--spacing-h);
  padding: var(--spacing-01);
  color: var(--color-neutral-00);
}

.alert-box .alert-box-header {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--spacing-01);
}

.alert-box .alert-box-header h3 {
  font-weight: 700;
  font-size: 0.8em;
  letter-spacing: 0.014em;
  text-transform: uppercase;
  margin: 0;
}

.alert-box .alert-box-icon {
  width: 16px;
  height: 16px;
  flex: 0 0 auto;
  display: block;
}

.alert-box.-with-content .alert-box-header {
  margin-bottom: var(--spacing-01);
}

.alert-box.-closable .alert-box-header {
  justify-content: space-between;
}

.alert-box.-error {
  border-color: var(--color-danger-02);
  background-color: var(--color-danger-01);
}

.alert-box.-error .alert-box-close-button,
.alert-box.-error .alert-box-header {
  color: var(--color-neutral-00);
}

.alert-box.-error .alert-box-icon {
  color: var(--color-neutral-00);
}

.alert-box.-success {
  border-color: var(--color-success-02);
  background-color: var(--color-success-01);
  color: var(--color-neutral-90);
}

.alert-box.-success .alert-box-header {
  color: var(--color-scalero);
}

.alert-box.-success .alert-box-icon {
  color: var(--color-neutral-90);
}

.alert-box.-warning {
  border-color: var(--color-warning-02);
  background-color: var(--color-warning-01);
  color: var(--color-neutral-90);
}

.alert-box.-warning .alert-box-header {
  color: var(--color-neutral-90);
}

.alert-box.-warning .alert-box-icon {
  color: var(--color-neutral-90);
}

.alert-box.-info {
  border-color: var(--color-info-02);
  background-color: var(--color-info-01);
}

.alert-box.-info .alert-box-header {
  color: var(--color-neutral-00);
}

.alert-box.-info .alert-box-icon {
  color: var(--color-neutral-00);
}
