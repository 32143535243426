.Toastify__toast {
  min-height: 0;
  font-weight: 700;
  border: 2px solid;
}

.Toastify__toast--default {
  color: var(--color-scalero);
}

.Toastify__toast--info {
  border-color: var(--color-info-02);
  background-color: var(--color-info-01);
  color: var(--color-neutral-00);
}

.Toastify__toast--info .Toastify__close-button > svg {
  color: var(--color-neutral-70);
}

.Toastify__toast--success {
  border-color: var(--color-success-02);
  background-color: var(--color-success-01);
  color: var(--color-scalero);
}

.Toastify__toast--success .Toastify__close-button > svg {
  color: var(--color-scalero);
}

.Toastify__toast--warning {
  border-color: var(--color-warning-02);
  background-color: var(--color-warning-01);
  color: var(--color-neutral-70);
}

.Toastify__toast--warning .Toastify__close-button > svg {
  color: var(--color-neutral-70);
}

.Toastify__toast--error {
  color: var(--color-neutral-70);
  border-color: var(--color-danger-02);
  background-color: var(--color-danger-01);
}

.Toastify__toast--error .Toastify__close-button > svg {
  color: var(--color-neutral-70);
}
