body {
  margin: 0;
  font-family: var(--font), -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-neutral-70);
}

h1 {
  font-weight: 600;
  font-size: var(--font-size-l);
  color: var(--color-neutral-90);
}

h2 {
  font-weight: 600;
  font-size: var(--font-size-m);
  color: var(--color-neutral-90);
}

h5 {
  margin: 0;
  padding: 0;
  font-size: var(--font-size-m);
  color: var(--color-neutral-70);
}

p {
  font-size: var(--font-size-m);
  line-height: var(--line-height-p);
}

a {
  color: var(--color-main-04);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

time {
  font-variant-numeric: tabular-nums;
}

label:not(.checkbox-control):not([class]) {
  margin-bottom: var(--spacing-01);
  display: block;
}

.label-tooltip {
  margin-bottom: var(--spacing-01);
}

.tooltip.label-tooltip label {
  margin-top: 0;
  margin-bottom: 0;
}

.label-tooltip div {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
}

.label-tooltip .tooltip-icon {
  margin-left: var(--spacing-01);
}

@media screen and (max-width: 700px) {
  .-hide-small {
    display: none !important;
  }
}

.SRLThumb {
  border-radius: var(--spacing-01);
}

button.SRLNextButton.SRLNextButton,
button.SRLPrevButton.SRLPrevButton,
button.SRLCloseButton.SRLCloseButton {
  border-radius: var(--spacing-h);
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -45%);
    content: var(--chevron-right);
    fill: var(--color-neutral-00);
    color: var(--color-neutral-00);
    stroke: var(--color-neutral-00);
  }
  & > div {
    display: none;
  }
}

button.SRLPrevButton.SRLPrevButton::before {
  content: var(--chevron-left);
}

button.SRLCloseButton.SRLCloseButton::before {
  content: var(--x);
}
