.input-container {
  display: flex;
  flex-flow: column;
}

.input-control {
  display: flex;
  flex-flow: row;
  position: relative;
}

.input-control input {
  width: 100%;
  appearance: none;
  background-color: var(--color-neutral-00);
  border-radius: var(--spacing-h);
  border: 1px solid var(--color-neutral-40);
  outline: none;
  transition: all 0.2s ease;
}

.input-container.-s input {
  padding: calc(var(--spacing-01) + 2px) var(--spacing-01);
  font-size: var(--font-size-s);
  line-height: var(--line-height-block);
}

.input-container.-m input {
  padding: var(--spacing-01);
  font-size: var(--font-size-m);
  line-height: var(--line-height-block);
}

.input-control input:focus {
  border-color: var(--color-primary-01);
}

.input-control input:hover {
  border-color: var(--color-neutral-80);
}

.input-control input:read-only,
.input-control input:disabled {
  background-color: var(--color-neutral-20);
  border-color: var(--color-neutral-30);
  color: var(--color-neutral-40);
}

.input-control input::placeholder {
  color: var(--color-neutral-40);
}

.input-container.-s.-with-sufix input {
  padding-right: calc(var(--spacing-02) + 20px);
}

.input-container.-m.-with-sufix input {
  padding-right: calc(var(--spacing-02) + 20px);
}

.input-container.-l.-with-sufix input {
  padding-right: calc(var(--spacing-03) + 20px);
}

.input-container.-s.-with-icon.-trailing input,
.input-container.-m.-with-icon.-trailing input,
.input-container.-l.-with-icon.-trailing input {
  padding-left: var(--spacing-03);
}

.input-container.-s.-with-icon.-leading input,
.input-container.-m.-with-icon.-leading input,
.input-container.-l.-with-icon.-leading input {
  padding-right: var(--spacing-03);
}

.input-container.-s label {
  font-size: var(--font-size-s);
  line-height: var(--line-height-block);
}

.input-container.-m label {
  font-size: var(--font-size-m);
  line-height: var(--line-height-block);
}

.input-control .input-control-leading-icon,
.input-control .input-control-trailing-icon,
.input-control .input-control-sufix {
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-neutral-40);
  transition: color 0.2s ease;
}

.input-control .input-control-leading-icon,
.input-control .input-control-sufix {
  right: var(--spacing-01);
}

.input-control .input-control-trailing-icon {
  left: var(--spacing-01);
}

.input-container.-s .input-control-leading-icon,
.input-container.-s .input-control-trailing-icon {
  width: 10px;
  height: auto;
}

.input-container.-m .input-control-leading-icon,
.input-container.-m .input-control-trailing-icon {
  width: 10px;
  height: auto;
}

.input-container .input-control input:focus + .input-control-icon {
  color: var(--color-main-05);
}
