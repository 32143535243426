.error-fallback-button-icon > div {
  border: 1px solid var(--color-danger-02);
  border-radius: var(--spacing-h);
  background-color: var(--color-danger-01);
  color: var(--color-danger-02);
  padding: var(--spacing-q) var(--spacing-h);
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}
