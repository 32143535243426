.full-page-loading {
  min-height: 50vh;
  min-width: 50vw;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.full-page-loading .spinner {
  width: 100px;
  height: 100px;
  color: var(--color-main-05);
}
