.tooltip-msg {
  color: var(--color-neutral-00);
  background-color: rgba(33, 33, 33, 0.9);
  padding: var(--spacing-01);
  border-radius: var(--spacing-h);
}

.tooltip-icon {
  width: 20px;
  height: 20px;
  color: var(--color-neutral-40);
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  background-color: var(--color-neutral-00);
  border-radius: 50%;
  border: 1px solid var(--color-neutral-40);
  transition: all 0.2s ease;
  cursor: help;
}

.tooltip:hover .tooltip-icon {
  background-color: var(--color-primary-01);
  color: var(--color-neutral-00);
  border-color: var(--color-primary-01);
}
