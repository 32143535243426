.layout.-horizontal {
  display: flex;
  flex-flow: row;
  width: 100%;
}

@media screen and (max-width: 700px) {
  .layout.-horizontal {
    flex-flow: column;
  }
}
