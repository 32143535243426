/* General */
.button-w-icon {
  appearance: none;
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--color-neutral-70);
  cursor: pointer;
  padding: 0;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: color 0.2s ease;
}

.button-w-icon::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: var(--spacing-03);
  height: var(--spacing-03);
  transform: translate(-50%, -50%);
  background: transparent;
  border-radius: 50%;
  z-index: 0;
  transition: background-color 0.2s ease;
}

.button-w-icon .button-icon {
  z-index: 1;
  height: var(--spacing-02);
  width: auto;
  stroke-width: 2px;
}

.button-w-icon:hover {
  color: var(--color-neutral-00);
}
.button-w-icon:hover::before {
  background: var(--color-primary-02);
}
.button-w-icon:active::before {
  background: var(--color-primary-03);
}
.button-w-icon.-disabled {
  color: var(--color-neutral-30);
  cursor: default;
}
.button-w-icon.-disabled::before {
  background: var(--color-neutral-20);
}

/* Sizes */

.button-w-icon.-s .button-icon {
  height: var(--spacing-01);
  width: auto;
}

.button-w-icon.-s::before {
  width: var(--spacing-02);
  height: var(--spacing-02);
}

.button-w-icon.-l .button-icon {
  height: var(--spacing-03);
  width: auto;
}

.button-w-icon.-l::before {
  width: var(--spacing-04);
  height: var(--spacing-04);
}

/* Danger */

.button-w-icon.-danger {
  color: var(--color-danger-01);
}

.button-w-icon.-danger:hover,
.button-w-icon.-danger:active {
  color: var(--color-neutral-00);
}

.button-w-icon.-danger:hover::before {
  background: var(--color-danger-02);
}

.button-w-icon.-danger:active::before {
  background: var(--color-danger-03);
}

.button-w-icon.-danger.-disabled {
  color: var(--color-neutral-30);
  cursor: default;
}

.button-w-icon.-danger.-disabled::before {
  background: var(--color-neutral-20);
}

/* Warning */

.button-w-icon.-warning {
  color: var(--color-warning-01);
}

.button-w-icon.-warning:hover,
.button-w-icon.-warning:active {
  color: var(--color-neutral-00);
}

.button-w-icon.-warning:hover::before {
  background: var(--color-warning-02);
}

.button-w-icon.-warning:active::before {
  background: var(--color-warning-03);
}

.button-w-icon.-warning.-disabled {
  color: var(--color-neutral-30);
  cursor: default;
}

.button-w-icon.-warning.-disabled::before {
  background: var(--color-neutral-20);
}

/* Success */

.button-w-icon.-success {
  color: var(--color-success-01);
}

.button-w-icon.-success:hover,
.button-w-icon.-success:active {
  color: var(--color-neutral-00);
}

.button-w-icon.-success:hover::before {
  background: var(--color-success-02);
}

.button-w-icon.-success:active::before {
  background: var(--color-success-03);
}

.button-w-icon.-success.-disabled {
  color: var(--color-neutral-30);
  cursor: default;
}

.button-w-icon.-success.-disabled::before {
  background: var(--color-neutral-20);
}

/* Info */

.button-w-icon.-info {
  color: var(--color-info-01);
}

.button-w-icon.-info:hover,
.button-w-icon.-info:active {
  color: var(--color-neutral-00);
}

.button-w-icon.-info:hover::before {
  background: var(--color-info-02);
}

.button-w-icon.-info:active::before {
  background: var(--color-info-03);
}

.button-w-icon.-info.-disabled {
  color: var(--color-neutral-30);
  cursor: default;
}

.button-w-icon.-info.-disabled::before {
  background: var(--color-neutral-20);
}

/* Dark */

.button-w-icon.-dark {
  color: var(--color-neutral-10);
}
.button-w-icon.-dark:hover,
.button-w-icon.-dark:active {
  color: var(--color-neutral-00);
}
.button-w-icon.-dark:hover::before {
  background: var(--color-dark-05);
}
.button-w-icon.-dark:active::before {
  background: var(--color-dark-06);
}

.button-w-icon.-dark.-disabled {
  color: var(--color-neutral-30);
  cursor: default;
}
.button-w-icon.-dark.-disabled::before {
  background: var(--color-neutral-20);
}
