.page {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.page main {
  width: 100%;
  flex: 1 1 auto;
}
