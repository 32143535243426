:root {
  /* -------------------------------- COLORS */
  --color-neutral-90: #232323;
  --color-neutral-80: #444;
  --color-neutral-70: #6e6e6e;
  --color-neutral-40: #8e8e8e;
  --color-neutral-30: #c4c4c4;
  --color-neutral-20: #e6e6e6;
  --color-neutral-10: #f4f4f4;
  --color-neutral-00: #fff;

  --color-primary-01: #00b592;
  --color-primary-02: #046e5a;
  --color-primary-03: #04473a;

  --color-secondary-01: #1a7a7a;
  --color-secondary-02: #195b57;
  --color-secondary-03: #144441;

  --color-tertiary-01: #586666;
  --color-tertiary-02: #344545;
  --color-tertiary-03: #213030;

  --color-danger-01: #ff4640;
  --color-danger-02: #e2433d;
  --color-danger-03: #b0150f;

  --color-warning-01: #ffd46d;
  --color-warning-02: #ffbf19;
  --color-warning-03: #e2a200;

  --color-success-01: #14ff80;
  --color-success-02: #11cb66;
  --color-success-03: #00803a;

  --color-info-01: #0154d3;
  --color-info-02: #07429d;
  --color-info-03: #025;

  --color-scalero: #00342e;
  --color-mint: #1defb5;
  --color-blue: #3b94e7;

  --color-dark-01: #132121;
  --color-dark-02: #253e3e;
  --color-dark-03: #305959;

  --color-dark-04: #385359;
  --color-dark-05: #2b454a;
  --color-dark-06: #071f24;

  /* -------------------------------- SPACING */

  --spacing-q: 2px;
  --spacing-h: 4px;
  --spacing-01: 8px;
  --spacing-02: 16px;
  --spacing-03: 24px;
  --spacing-04: 32px;
  --spacing-05: 40px;
  --spacing-06: 48px;
  --spacing-07: 56px;
  --spacing-08: 64px;
  --spacing-09: 72px;
  --spacing-10: 80px;

  /* -------------------------------- TYPOGRAPHY */
  --font-size-s: 11px;
  --font-size-m: 14px;
  --font-size-l: 20px;

  --line-height-p: 1.65;
  --line-height-block: 1.14;

  --font: 'Inter', sans-serif;

  --color-main-01: #a8dfd0;
  --color-main-02: #5bfeb1;
  --color-main-03: #2bdea2;
  --color-main-04: #00b382;
  --color-main-05: #3b7068;

  --color-status-01: #c4c4c4;
  --color-status-02: #ffb300;
  --color-status-03: #00b592;

  --color-action-01: #3b94e7;
  --color-action-02: #9b3be7;
  --color-action-03: #1defb5;
  --color-action-04: #fc6f6a;

  --bg-checkboard: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 8 8'%3E%3Cg fill='%23000000' fill-opacity='0.2'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");

  --chevron-right: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1jaGV2cm9uLXJpZ2h0Ij48cG9seWxpbmUgcG9pbnRzPSI5IDE4IDE1IDEyIDkgNiI+PC9wb2x5bGluZT48L3N2Zz4=');
  --chevron-left: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1jaGV2cm9uLWxlZnQiPjxwb2x5bGluZSBwb2ludHM9IjE1IDE4IDkgMTIgMTUgNiI+PC9wb2x5bGluZT48L3N2Zz4=');
  --x: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci14Ij48bGluZSB4MT0iMTgiIHkxPSI2IiB4Mj0iNiIgeTI9IjE4Ij48L2xpbmU+PGxpbmUgeDE9IjYiIHkxPSI2IiB4Mj0iMTgiIHkyPSIxOCI+PC9saW5lPjwvc3ZnPg==');
}
