.button {
  text-decoration: none;
  appearance: none;
  border: 1px solid transparent;
  outline: none;
  text-transform: uppercase;
  color: var(--color-neutral-00);
  cursor: pointer;
  border-radius: var(--spacing-h);
  transition: all 0.2s ease;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-weight: 600;
  line-height: var(--line-height-block);
}

.button .button-icon {
  margin-right: var(--spacing-01);
  font-weight: 600;
}

.button .button-loading {
  margin-left: var(--spacing-01);
}

.button.-outline {
  font-weight: normal;
  line-height: var(--line-height-block);
}

.button:disabled {
  background-color: var(--color-neutral-20);
  color: var(--color-neutral-30);
  border-color: var(--color-neutral-30);
}

.button.-s {
  text-transform: capitalize;
  font-size: var(--font-size-s);
  padding: var(--spacing-h) var(--spacing-02);
}

.button.-s .button-icon {
  margin-right: var(--spacing-h);
}

.button.-s.-outline {
  font-weight: 600;
}

.button.-m {
  font-size: var(--font-size-m);
  padding: var(--spacing-01) var(--spacing-02);
}

.button.-l {
  font-size: var(--font-size-m);
}

.button a {
  text-decoration: none;
  color: inherit;
}

/*  --------------------------------- Primary */

/* ? For some reason it needs default modifier selector for the other rules to work */
.button.-primary {
  background-color: transparent;
}

.button.-primary:not(.-disabled) {
  background-color: var(--color-primary-01);
}

.button.-primary:hover {
  background-color: var(--color-primary-02);
}

.button.-primary:active {
  background-color: var(--color-primary-03);
}

.button.-primary.-outline {
  background-color: transparent;
  border-color: var(--color-primary-01);
  color: var(--color-primary-01);
}

.button.-primary.-outline:disabled {
  background-color: var(--color-neutral-20);
  color: var(--color-neutral-30);
  border-color: var(--color-neutral-30);
}

.button.-primary.-outline:hover {
  background-color: var(--color-primary-02);
  border-color: var(--color-primary-02);
  color: var(--color-neutral-00);
  line-height: var(--line-height-block);
}

.button.-primary.-outline:active {
  background-color: var(--color-primary-03);
}

.button.-primary.-text {
  border: none;
  background-color: transparent;
  color: var(--color-primary-01);
  font-size: var(--font-size-m);
}

.button.-primary.-text:hover {
  color: var(--color-primary-02);
}

.button.-primary.-text:active {
  color: var(--color-primary-03);
}

.button.-primary.-text:disabled {
  color: var(--color-neutral-30);
}

/*  ---------------------------------  Secondary */

/* ? For some reason it needs default modifier selector for the other rules to work */
.button.-secondary {
  background-color: transparent;
}

.button.-secondary:not(.-disabled) {
  background-color: var(--color-secondary-01);
}

.button.-secondary:hover {
  background-color: var(--color-secondary-02);
}

.button.-secondary:active {
  background-color: var(--color-secondary-03);
}

.button.-secondary.-outline {
  background-color: transparent;
  border-color: var(--color-secondary-01);
  color: var(--color-secondary-01);
}

.button.-secondary.-outline:disabled {
  background-color: var(--color-neutral-20);
  color: var(--color-neutral-30);
  border-color: var(--color-neutral-30);
}

.button.-secondary.-outline:hover {
  background-color: var(--color-secondary-02);
  border-color: var(--color-secondary-02);
  color: var(--color-neutral-00);
  line-height: var(--line-height-block);
}

.button.-secondary.-outline:active {
  background-color: var(--color-secondary-03);
}

.button.-secondary.-text {
  border: none;
  background-color: transparent;
  color: var(--color-secondary-01);
  font-size: var(--font-size-m);
}

.button.-secondary.-text:hover {
  color: var(--color-secondary-02);
}

.button.-secondary.-text:active {
  color: var(--color-secondary-03);
}

.button.-secondary.-text:disabled {
  color: var(--color-neutral-30);
}

/*  ---------------------------------  Tertiary */

/* ? For some reason it needs default modifier selector for the other rules to work */
.button.-tertiary {
  background-color: transparent;
}

.button.-tertiary:not(.-disabled) {
  background-color: var(--color-tertiary-01);
}

.button.-tertiary:hover {
  background-color: var(--color-tertiary-02);
}

.button.-tertiary:active {
  background-color: var(--color-tertiary-03);
}

.button.-tertiary.-outline {
  background-color: transparent;
  border-color: var(--color-tertiary-01);
  color: var(--color-tertiary-01);
}

.button.-tertiary.-outline:disabled {
  background-color: var(--color-neutral-20);
  color: var(--color-neutral-30);
  border-color: var(--color-neutral-30);
}

.button.-tertiary.-outline:hover {
  background-color: var(--color-tertiary-02);
  border-color: var(--color-tertiary-02);
  color: var(--color-neutral-00);
  line-height: var(--line-height-block);
}

.button.-tertiary.-outline:active {
  background-color: var(--color-tertiary-03);
}

.button.-tertiary.-text {
  border: none;
  background-color: transparent;
  color: var(--color-tertiary-01);
  font-size: var(--font-size-m);
}

.button.-tertiary.-text:hover {
  color: var(--color-tertiary-02);
}

.button.-tertiary.-text:active {
  color: var(--color-tertiary-03);
}

.button.-tertiary.-text:disabled {
  color: var(--color-neutral-30);
}

/*  --------------------------------- Danger */

/* ? For some reason it needs default modifier selector for the other rules to work */
.button.-danger {
  background-color: transparent;
}

.button.-danger:not(.-disabled) {
  background-color: var(--color-danger-01);
}

.button.-danger:hover {
  background-color: var(--color-danger-02);
}

.button.-danger:active {
  background-color: var(--color-danger-03);
}

.button.-danger.-outline {
  background-color: transparent;
  border-color: var(--color-danger-01);
  color: var(--color-danger-01);
}

.button.-danger.-outline:hover {
  background-color: var(--color-danger-02);
  border-color: var(--color-danger-02);
  color: var(--color-neutral-00);
  line-height: var(--line-height-block);
}

.button.-danger.-outline:active {
  background-color: var(--color-danger-03);
}
